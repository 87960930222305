<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">

          <!-- Logo & Text -->
          <li class="nav-item mr-auto">
            <b-link
              class="navbar-brand"
              to="/"
            >
              <span>
                <b-img
                  :src="appLogoImage"
                  alt="logo"
                  width="80px"
                  height="auto"
                />
              </span>
              <!-- <h2 class="brand-text">
                {{ appName }}
              </h2> -->
            </b-link>
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="nav"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical'
// import navMenuItemsSupplier from '@/navigation/vertical/supplier'
// import navMenuItemsManager from '@/navigation/vertical/manager'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import { provide, computed, ref } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import storeAuth from '@/store/store'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    nav() {
      const { permissions } = storeAuth.state
      const { role } = storeAuth.state.user
      function checkPermissions(permission) {
        return permissions.find(v => v.codename === permission)
      }
      let dashboard = null
      let configuration = null
      let employees = null
      let teacher = null
      let emploi = null
      let etude = null
      let programme = null
      let etudiant = null
      let ensignant = null
      let note = null
      let finance = null
      let presence = null
      let subject = null
      let emploies = null
      if (role === 'admin') {
        // module dashboard
        if (checkPermissions('view')) {
          dashboard = {
            title: 'Tableau de bord',
            route: 'dashboard',
            icon: 'HomeIcon',
          }
        }
        // module configuration
        if (checkPermissions('view_academicyear')) {
          configuration = {
            title: 'Configuration',
            icon: 'SettingsIcon',
            children: [
              {
                title: 'Années universitaires',
                route: 'academic-year-list',
                icon: 'UnlockIcon',
              },
            ],
          }
        }
        if (checkPermissions('view_university')) {
          configuration = {
            title: 'Configuration',
            icon: 'SettingsIcon',
            children: [
              {
                title: 'Années universitaires',
                route: 'academic-year-list',
                icon: 'UnlockIcon',

              },
            ],
          }
        }
        if (checkPermissions('view_academicyear') && checkPermissions('view_university')) {
          configuration = {
            title: 'Configuration',
            icon: 'SettingsIcon',
            children: [
              {
                title: 'Information Général',
                route: 'information-university-add',
                icon: 'EditIcon',

              },
              {
                title: 'Années universitaires',
                route: 'academic-year-list',
                icon: 'UnlockIcon',

              },
              {
                title: 'Semaines',
                route: 'weeks-list',
                icon: 'CalendarIcon',
              },
              {
                title: 'Seances',
                route: 'seances-list',
                icon: 'BookOpenIcon',
              },
              {
                title: 'Frais Scolaire',
                route: 'frais-scolaire-list',
                icon: 'DollarSignIcon',

              },
            ],
          }
        }
        // module employees
        if (checkPermissions('view_user') && checkPermissions('add_user') && checkPermissions('view_group')) {
          employees = {
            title: 'Employés',
            icon: 'UsersIcon',
            children: [
              {
                title: 'role',
                route: 'role-list',
                icon: 'UserIcon',

              },
              {
                title: 'List employés',
                route: 'employee-list',
                icon: 'ListIcon',
              },
              {
                title: 'Ajouter employé',
                route: 'employee-add',
                icon: 'UserPlusIcon',
              },

            ],
          }
        }
        // module emploi
        emploi = {
          title: 'Emplois',
          icon: 'ArchiveIcon',
          children: [
            {
              title: 'Répartition Matières',
              route: 'distribution-subjects-list',
              icon: 'CheckCircleIcon',
            },
            {
              title: 'Répartition Enseignant',
              route: 'distribution-subjects-teacher-list',
              icon: 'CheckCircleIcon',
            },
            {
              title: 'Emplois Universitaires',
              route: 'emplois-universitaires',
              icon: 'CalendarIcon',
            },
          ],
        }
        // module etude
        etude = {
          title: 'Etudes',
          icon: 'ArchiveIcon',
          children: [
            {
              title: 'Groups',
              route: 'groups-list',
              icon: 'UsersIcon',
            },

            {
              title: 'Gestion Salles',
              route: 'rooms-list',
              icon: 'ClipboardIcon',
            },
            {
              title: 'Jours Feriés',
              route: 'holidays-list',
              icon: 'SunIcon',
            },
            {
              title: 'Activation des Séances',
              route: 'absence-list',
              icon: 'CheckCircleIcon',
            },
            {
              title: 'Gestion Rattrapages',
              route: 'absence-list',
              icon: 'GlobeIcon',
            },
            {
              title: 'Gestion Absences',
              route: 'absence-list',
              icon: 'GlobeIcon',
            },

          ],
        }
        // module programme
        programme = {
          title: 'Programmes',
          icon: 'DatabaseIcon',
          children: [
            {
              title: 'Programme',
              route: 'programs-list',
              icon: 'TargetIcon',

            },
            {
              title: 'Département',
              route: 'departements-list',
              icon: 'HomeIcon',

            },
            {
              title: 'Diplômes ',
              route: 'diplomas-list',
              icon: 'AwardIcon',

            },
            {
              title: 'Filières ',
              route: 'filieres-list',
              icon: 'FolderPlusIcon',

            },
            {
              title: 'Parcours',
              route: 'parcours-list',
              icon: 'BookmarkIcon',

            },
            {
              title: 'Matières',
              route: 'subjects-list',
              icon: 'BookOpenIcon',

            },
            {
              title: 'Unité d`enseignement',
              route: 'teaching-units-list',
              icon: 'TargetIcon',

            },
            {
              title: 'Certifications',
              route: 'certificats-list',
              icon: 'AwardIcon',

            },

          ],
        }
        // module etudiant
        etudiant = {
          title: 'Etudiants',
          icon: 'UsersIcon',
          children: [
            {
              title: 'Gestion Etudiants',
              route: 'student-list',
              icon: 'UsersIcon',

            },
            {
              title: 'Gestion inscription',
              route: 'inscriptions-list',
              icon: 'UsersIcon',
            },
            {
              title: 'Gestion prospect',
              route: 'prospects-list',
              icon: 'UsersIcon',
            },
            {
              title: 'Gestion absence',
              route: 'absences-list',
              icon: 'UserXIcon',
            },

          ],
        }
        // module ensignant
        ensignant = {
          title: 'Enseignants',
          icon: 'UsersIcon',
          children: [
            {
              title: 'List Enseignant',
              route: 'teacher-list',
              icon: 'BriefcaseIcon',

            },
            {
              title: 'Ajouter Enseignant',
              route: 'teacher-add',
              icon: 'UserPlusIcon',
            },
            {
              title: 'List Grade',
              route: 'grades-list',
              icon: 'BriefcaseIcon',

            },
            {
              title: 'Ajouter Grade',
              route: 'grade-add',
              icon: 'UserPlusIcon',
            },

          ],
        }
        // module note
        note = {
          title: 'Notes',
          icon: 'DivideSquareIcon',
          children: [
            {
              title: 'Notes Session Principale',
              route: 'principal-notes-list',
              icon: 'TargetIcon',
            },
            {
              title: 'Validation des Notes',
              route: 'note-valid',
              icon: 'TargetIcon',
            },
            {
              title: 'Delibrations Principale',
              route: 'principal-deliberations',
              icon: 'TargetIcon',
            },
            {
              title: 'Notes Session Rattrapage',
              route: '',
              icon: 'TargetIcon',

            },
            {
              title: 'Delibrations Rattrapage',
              route: '',
              icon: 'TargetIcon',
            },

          ],
        }
        // module finance
        finance = {
          title: 'Finance',
          icon: 'LockIcon',
          children: [
            {
              title: 'Banques',
              route: 'bank-list',
              icon: 'TargetIcon',

            },
            {
              title: 'Transaction Etudiant',
              route: 'payments-list',
              icon: 'TargetIcon',
            },
            {
              title: 'Etat Paiement',
              route: 'payments-etat',
              icon: 'TargetIcon',
            },
            {
              title: 'Etat de Rapprochement',
              route: 'absence-list',
              icon: 'TargetIcon',
            },

          ],
        }
        // // module jour ferier
        // finance = {
        //   title: 'Jour Férier',
        //   icon: 'DatabaseIcon',
        //   children: [
        //     {
        //       title: 'Listes',
        //       route: 'holidays-list',
        //       icon: 'TargetIcon',

        //     },
        //     {
        //       title: 'Ajouter Jour Férier',
        //       route: 'holidays-add',
        //       icon: 'UserPlusIcon',
        //     },

        //   ],
        // }

        // // module jour Certificats
        // finance = {
        //   title: 'Certificats',
        //   icon: 'ArchiveIcon',
        //   children: [
        //     {
        //       title: 'Listes',
        //       route: 'certificats-list',
        //       icon: 'TargetIcon',

        //     },
        //     {
        //       title: 'Ajouter Certificats',
        //       route: 'holidays-add',
        //       icon: 'UserPlusIcon',
        //     },

        //   ],
        // }
      } else if (role === 'teacher') {
        teacher = {
          title: 'Tableau de bord ensignant',
          route: 'dashboard',
          icon: 'HomeIcon',
        }
        presence = {
          title: 'Présences',
          route: 'list-presences',
          icon: 'SunIcon',
        }
        note = {
          title: 'Mes Notes',
          route: 'list-notes',
          icon: 'DivideSquareIcon',
        }
        subject = {
          title: 'Mes Matiére',
          route: 'list-subject',
          icon: 'DivideSquareIcon',
        }
        emploies = {
          title: 'Mes Emploies',
          route: 'list-emploies',
          icon: 'CalendarIcon ',
        }
      }
      const menu = [
        dashboard,
        configuration,
        employees,
        teacher,
        emploi,
        etude,
        programme,
        etudiant,
        ensignant,
        finance,
        presence,
        note,
        subject,
        emploies,

      ]

      return menu.filter(element => element !== null)
    },

  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
