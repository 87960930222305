import storeAuth from '@/store/store'

const { permissions } = storeAuth.state
function checkPermissions(permission) {
  return permissions.find(v => v.codename === permission)
}

let dashboard = null
if (checkPermissions('view')) {
  dashboard = {
    title: 'Tableau de bord',
    route: 'dashboard',
    icon: 'HomeIcon',
  }
}

let configuration = null
if (checkPermissions('view_academicyear')) {
  configuration = {
    title: 'Configuration',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Information Universitaire',
        route: 'information-university-add',
        icon: 'EditIcon',

      },
      {
        title: 'Années universitaires',
        route: 'academic-year-list',
        icon: 'UnlockIcon',

      },
    ],
  }
}

const menu = [
  dashboard,
  configuration,

  {
    title: 'Employés',
    icon: 'UsersIcon',
    children: [
      {
        title: 'role',
        route: 'role-list',
        icon: 'UserIcon',

      },
      {
        title: 'Permission',
        route: 'permission-list',
        icon: 'UserCheckIcon',
      },
      {
        title: 'List employés',
        route: 'employee-list',
        icon: 'ListIcon',
      },
      {
        title: 'Ajouter employé',
        route: 'employee-add',
        icon: 'TargetIcon',
      },

    ],
  },

  {
    title: 'Programmes',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Programme',
        route: 'programme-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Département',
        route: 'departements-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Diplômes ',
        route: 'diplomas-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Filières ',
        route: 'filieres-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Parcours',
        route: 'parcours-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Matières',
        route: 'subjects-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Unité d`enseignement',
        route: 'unite-enseignement-list',
        icon: 'TargetIcon',

      },

    ],
  },
  {
    title: 'Finance',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Banques',
        route: 'bank-list',
        icon: 'ListIcon',

      },
      {
        title: 'Transaction Etudiant',
        route: 'inscription-list',
        icon: 'ListIcon',
      },
      {
        title: 'Etat Paiement',
        route: 'prospect-list',
        icon: 'ListIcon',
      },
      {
        title: 'Etat de Rapprochement',
        route: 'absence-list',
        icon: 'ListIcon',
      },

    ],
  },

  {
    title: 'Etudiants',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Gestion Etudiants',
        route: 'student-list',
        icon: 'ListIcon',

      },
      {
        title: 'Gestion inscription',
        route: 'inscription-list',
        icon: 'ListIcon',
      },
      {
        title: 'Gestion prospect',
        route: 'prospect-list',
        icon: 'ListIcon',
      },
      {
        title: 'Gestion absence',
        route: 'absences-list',
        icon: 'ListIcon',
      },

    ],
  },

  {
    title: 'Enseignants',
    icon: 'UsersIcon',
    children: [
      {
        title: 'List Enseignant',
        route: 'teacher-list',
        icon: 'TargetIcon',

      },
      {
        title: 'Ajouter Enseignant',
        route: 'teacher-add',
        icon: 'TargetIcon',
      },

    ],
  },

  {
    title: 'Notes',
    icon: 'DivideIcon',
    children: [
      {
        title: 'Notes Session Principale',
        route: '',
        icon: 'TargetIcon',

      },
      {
        title: 'Delibrations Principale',
        route: '',
        icon: 'TargetIcon',
      },
      {
        title: 'Notes Session Rattrapage',
        route: '',
        icon: 'TargetIcon',

      },
      {
        title: 'Delibrations Rattrapage',
        route: '',
        icon: 'TargetIcon',
      },

    ],
  },
]

export default menu
